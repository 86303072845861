import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function GetADemo() {
  return (
    <Layout
      title="View IrisAgent's webinar recording"
      keywords="IrisAgent, webinar, incidents, tickets"
      description="View the recording of our webinar on how to improve workflows between product, engineering, and support. IrisAgent AI connects product context with customer support tickets in real-time to uncover the true source of issues, reduce escalations, and improve CSAT."
    >
      <Helmet>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link rel="canonical" href="https://irisagent.com/webinar/watch-webinar/" />
      </Helmet>

      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Watch Webinar Recording!</h1>
                <br />
                <p>
                  Learn how to improve workflows between product, engineering,
                  and support.
                  <br />
                  <br />
                  Many companies today are leaving cross functional data silos
                  (support, engineering, success, and product) disconnected and
                  underlevered, yet turn around to ask why customer support
                  spending is high, escalations are reoccurring, and churn rates
                  are not where they should be - costing time, money, and
                  customers.
                  <br />
                  <br />
                  With the right technology and processes, learn how you can
                  seamlessly integrate your CRM, support platforms, and DevOps
                  tools and reduce ticket resolution times, improve agent
                  performance, and uncover siloed information to reduce
                  escalations.
                </p>
                <img
                  src="/img/hero-img.png"
                  className="img-fluid"
                  alt="Schedule-demo"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                {/* <HubspotForm
                  portalId='7494256'
                  formId='29f4e374-b150-48db-9a15-668eeac19625'
                  loading={<div>Loading...</div>}
                /> */}
                <HubspotForm
                  portalId="7494256"
                  formId="df9b63b1-3364-4e36-ba65-fe9595b25d1f"
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
